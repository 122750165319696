import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Layout } from '~/components/molecules/Layout';
import { SEO } from '~/components/atoms/SEO';
// eslint-disable-next-line import/no-cycle
import DataSecurityPage from './DataSecurityPage/DataSecurityPage';
import { TranslationContext } from '~/utils/styles/utils';

const DataSecurity: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <SEO
        title="Datenschutzbestimmungen des Neurozentrum Arabellapark in Bogenhausen."
        metaDescription="Neurologie Bogenhausen München: Dr. Sabine Uez ist Ihre Spezialistin für Erkrankungen des Nervensystems. Vereinbaren Sie hier einen Termin."
      />
      <TranslationContext.Provider value={t}>
        <DataSecurityPage />
      </TranslationContext.Provider>
    </Layout>
  );
};

export const useTranslationContext = () => React.useContext(TranslationContext);

export default DataSecurity;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["commonpage"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
