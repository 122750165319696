import React from 'react';
import styled from '@emotion/styled';
import { Container, ContainerSmall } from '~/utils/styles/utils';

const ImprintMain: React.FC = () => {
  return (
    <>
      <Container>
        <ContainerSmall>
          <ContainerSmallOverwritten>
            <h1>Datenschutzerklärung</h1>
            <p>
              Der Betreiber dieser Seiten nimmt den Schutz Ihrer persönlichen
              Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
              vertraulich und entsprechend den gesetzlichen
              Datenschutzvorschriften sowie dieser Datenschutzerklärung. Die
              Nutzung unserer Webseite ist in der Regel ohne Angabe
              personenbezogener Daten möglich. Soweit auf unseren Seiten
              personenbezogene Daten (beispielsweise Name, Anschrift oder
              E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich,
              stets auf freiwilliger Basis. Diese Daten werden ohne Ihre
              ausdrückliche Zustimmung nicht an Dritte weitergegeben.
            </p>
            <p>
              Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B.
              bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen
              kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch
              Dritte ist nicht möglich.
            </p>
            <h2>
              1. Name und Kontaktdaten des für die Verarbeitung Verantwortlichen
              sowie des betrieblichen Datenschutzbeauftragten
            </h2>
            <p>
              Diese Datenschutz-Information gilt für die Datenverarbeitung
              durch:
            </p>
            <p>
              Verantwortliche: Dr. Sabine Uez, Rosenkavalierplatz 18, 81925
              München Tel: 089/95006766 ; E-Mail:
              info@neurozentrum-arabellapark.de
            </p>
            <h2>
              2. Erhebung und Speicherung personenbezogener Daten sowie Art und
              Zweck von deren Verwendung
            </h2>
            <h3>Beim Besuch der Webseite</h3>
            <p>
              Beim Aufrufen unserer Webseite werden durch den auf Ihrem Endgerät
              zum Einsatz kommenden Browser automatisch Informationen an den
              Server unserer Webseite gesendet. Diese Informationen werden
              temporär in einem sog. Logfile gespeichert. Folgende Informationen
              werden dabei ohne Ihr Zutun erfasst und bis zur automatisierten
              Löschung gespeichert:
            </p>
            <ul>
              <li>IP-Adresse des anfragenden Rechners,</li>
              <li>Datum und Uhrzeit des Zugriffs,</li>
              <li>Name und URL der abgerufenen Datei,</li>
              <li>Webseite, von der aus der Zugriff erfolgt (Referrer-URL),</li>
              <li>
                verwendeter Browser und ggf. das Betriebssystem Ihres Rechners
                sowie der Name Ihres Access-Providers.
              </li>
            </ul>
            <p>
              Die genannten Daten werden durch uns zu folgenden Zwecken
              verarbeitet:
            </p>
            <ul>
              <li>
                Gewährleistung eines reibungslosen Verbindungsaufbaus der
                Webseite,
              </li>
              <li>
                Gewährleistung einer komfortablen Nutzung unserer Webseite,
              </li>
              <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
              <li>zu weiteren administrativen Zwecken.</li>
            </ul>
            <p>
              Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S.
              1 lit. f DSGVO. Unser berechtigtes Interesse folgt aus den oben
              aufgelisteten Zwecken der Datenerhebung. In keinem Fall verwenden
              wir die erhobenen Daten zu dem Zweck, Rückschlüsse auf Ihre Person
              zu ziehen.
            </p>
            <h2>3. Weitergabe von Daten</h2>
            <p>
              Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen
              als den in dieser Datenschutzerklärung aufgeführten Zwecken findet
              nicht statt.
            </p>
            <p>Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:</p>
            <ul>
              <li>
                Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrückliche
                Einwilligung dazu erteilt haben,
              </li>
              <li>
                die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur
                Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
                erforderlich ist und kein Grund zur Annahme besteht, dass Sie
                ein überwiegendes schutzwürdiges Interesse an der
                Nichtweitergabe Ihrer Daten haben,
              </li>
              <li>
                für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1
                lit. c DSGVO eine gesetzliche Verpflichtung besteht, sowie
              </li>
              <li>
                dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b
                DSGVO für die Abwicklung von Vertragsverhältnissen mit Ihnen
                erforderlich ist.
              </li>
            </ul>
            <h2>4. Betroffenenrechte</h2>
            <p>Sie haben das Recht:</p>
            <ul>
              <li>
                gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten
                personenbezogenen Daten zu verlangen. Insbesondere können Sie
                Auskunft über die Verarbeitungszwecke, die Kategorie der
                personenbezogenen Daten, die Kategorien von Empfängern,
                gegenüber denen Ihre Daten offengelegt wurden oder werden, die
                geplante Speicherdauer, das Bestehen eines Rechts auf
                Berichtigung, Löschung, Einschränkung der Verarbeitung oder
                Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft
                ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie
                über das Bestehen einer automatisierten Entscheidungsfindung
                einschließlich Profiling und ggf. aussagekräftigen Informationen
                zu deren Einzelheiten verlangen;
              </li>
              <li>
                gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger
                oder Vervollständigung Ihrer bei uns gespeicherten
                personenbezogenen Daten zu verlangen;
              </li>
              <li>
                gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten
                personenbezogenen Daten zu verlangen, soweit nicht die
                Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung
                und Information, zur Erfüllung einer rechtlichen Verpflichtung,
                aus Gründen des öffentlichen Interesses oder zur Geltendmachung,
                Ausübung oder Verteidigung von Rechtsansprüchen erforderlich
                ist;
              </li>
              <li>
                gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer
                personenbezogenen Daten zu verlangen, soweit die Richtigkeit der
                Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig
                ist, Sie aber deren Löschung ablehnen und wir die Daten nicht
                mehr benötigen, Sie jedoch diese zur Geltendmachung, Ausübung
                oder Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß
                Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt
                haben;
              </li>
              <li>
                gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns
                bereitgestellt haben, in einem strukturierten, gängigen und
                maschinenlesebaren Format zu erhalten oder die Übermittlung an
                einen anderen Verantwortlichen zu verlangen;
              </li>
              <li>
                gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung
                jederzeit gegenüber uns zu widerrufen. Dies hat zur Folge, dass
                wir die Datenverarbeitung, die auf dieser Einwilligung beruhte,
                für die Zukunft nicht mehr fortführen dürfen und
              </li>
              <li>
                gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu
                beschweren. In der Regel können Sie sich hierfür an die
                Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder
                Arbeitsplatzes oder unseres Unternehmenssitzes wenden.
              </li>
            </ul>
            <h2>5. Widerspruchsrecht</h2>
            <p>
              Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten
              Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO verarbeitet
              werden, haben Sie das Recht, gemäß Art. 21 DSGVO Widerspruch gegen
              die Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit
              dafür Gründe vorliegen, die sich aus Ihrer besonderen Situation
              ergeben oder sich der Widerspruch gegen Direktwerbung richtet. Im
              letzteren Fall haben Sie ein generelles Widerspruchsrecht, das
              ohne Angabe einer besonderen Situation von uns umgesetzt wird.
            </p>
            <p>
              Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch
              machen, genügt eine E-Mail an info@neurozentrum-arabellapark.de
            </p>
            <h2>6. Datensicherheit</h2>
            <p>
              Wir verwenden innerhalb des Webseite-Besuchs das verbreitete
              SSL-Verfahren (Secure Socket Layer) in Verbindung mit der jeweils
              höchsten Verschlüsselungsstufe, die von Ihrem Browser unterstützt
              wird. In der Regel handelt es sich dabei um eine 256 Bit
              Verschlüsselung. Falls Ihr Browser keine 256-Bit Verschlüsselung
              unterstützt, greifen wir stattdessen auf 128-Bit v3 Technologie
              zurück. Ob eine einzelne Seite unseres Internetauftrittes
              verschlüsselt übertragen wird, erkennen Sie an der geschlossenen
              Darstellung des Schüssel- beziehungsweise Schloss-Symbols in der
              unteren Statusleiste Ihres Browsers.
            </p>
            <h2>7. Jameda</h2>
            <p>
              Auf unserer Internetseite sind Siegel oder Widgets der jameda
              GmbH, St. Cajetan-Straße 41, 81669 München, eingebunden. Ein
              Widget ist ein kleines Fenster, das veränderliche Informationen
              anzeigt. Auch unser Siegel funktioniert in ähnlicher Weise, d. h.
              es sieht nicht immer gleich aus, sondern die Anzeige ändert sich
              regelmäßig. Dabei wird der entsprechende Inhalt zwar auf unserer
              Internetseite dargestellt, er wird aber in diesem Moment von den
              jameda-Servern abgerufen. Nur so kann immer der aktuelle Inhalt
              gezeigt werden, vor allem die jeweils aktuelle Bewertung. Dafür
              muss eine Datenverbindung von dieser Internetseite zu jameda
              aufgebaut werden und jameda erhält gewisse technische Daten (Datum
              und Uhrzeit des Besuchs; die Seite, von der die Abfrage erfolgt;
              verwendete Internet Protokoll-Adresse (IP-Adresse), Browsertyp und
              -version, Gerätetyp, Betriebssystem und ähnliche technische
              Informationen), die nötig sind, damit der Inhalt ausgeliefert
              werden kann. Diese Daten werden lediglich für die Bereitstellung
              des Inhalts verwendet und nicht gespeichert oder anderweitig
              genutzt. Wir verfolgen mit der Einbindung den Zweck und das
              berechtigte Interesse, aktuelle und korrekte Inhalte auf unserer
              Homepage darzustellen. Rechtsgrundlage ist Art 6 Abs. 1 f) DSGVO.
              Eine Speicherung der genannten Daten erfolgt durch uns aufgrund
              dieser Einbindung nicht. Zur Terminvereinbarung werden Sie auf
              www.jameda.de weitergeleitet. jameda verarbeitet die fort von
              Ihnen angegeben Daten dabei in eigener Verantwortung. Weitere
              Informationen zur Datenverarbeitung durch jameda können Sie der
              Datenschutzerklärung der Seite
              www.jameda.de/jameda/datenschutz.php entnehmen.
            </p>
            <h2>8. Aktualität und Änderung dieser Datenschutzerklärung</h2>
            <p>
              Diese Datenschutzerklärung ist aktuell gültig und hat den Stand
              24.11.2020
            </p>
            <p>
              Durch die Weiterentwicklung unserer Webseite und Angebote darüber
              oder aufgrund geänderter gesetzlicher beziehungsweise behördlicher
              Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu
              ändern. Die jeweils aktuelle Datenschutzerklärung kann jederzeit
              auf der Webseite unter
              https://www.neurozentrum-arabellapark.de/datenschutz von Ihnen
              abgerufen und ausgedruckt werden.
            </p>
          </ContainerSmallOverwritten>
        </ContainerSmall>
      </Container>
    </>
  );
};

const ContainerSmallOverwritten = styled.div`
  li {
    margin-left: 2rem;
    margin-right: 2rem;
  }
`;

export default ImprintMain;
