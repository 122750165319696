import React from 'react';
import NeuroRoomJPG from '@assets/images/neuro-room.jpg';
import NeuroRoomMobileJPG from '@assets/images/neuro-room-mobile.jpg';
import DataSecurityMain from '~/components/organisms/DataSecurityMain/DataSecurityMain';
import HeaderFooterLayout from '~/components/molecules/HeaderFooterLayout/HeaderFooterLayout';

// eslint-disable-next-line import/no-cycle
import { useTranslationContext } from '~/pages/datenschutz';

const DataSecurityPage: React.FC = () => {
  const t = useTranslationContext();

  return (
    <>
      <HeaderFooterLayout
        desktopHero={NeuroRoomJPG}
        mobileHero={NeuroRoomMobileJPG}
        altHero="Neurologie Praxisraum"
        t={t}
      >
        <DataSecurityMain />
      </HeaderFooterLayout>
    </>
  );
};

export default DataSecurityPage;
